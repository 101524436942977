module.exports = {
  google: {
    RECAPTCHA_KEY: "6Lf-jPMpAAAAAJxtZ-JYA-iXwmLmQfv0_pcQY37A",
  },
  optiroute: {
    ENTERPRISE_UUID: "3b0a8ee6-0e9a-4117-ada4-3fa066fe11d3",
  },
  api: {
    API_URL: "https://app.optiroute.cl/api/v1/",
  },
  emailjs_settings: {
    TEMPLATE_ID: "template_9waeoef",
    SERVICE_ID: "service_eapn5pq",
    PUBLIC_KEY: "dkT0W-Vv1nLbFAmLf",
  }
};
